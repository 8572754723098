import Image from "next/image";
import Link from "next/link";

import Oferta from "../../public/qrs.png";
import Cerca from "../../public/cerca-de.png";
import Totem from "../../public/totem.png";

export const Experience = () => {
  return (
    <>
      <section className=" py-12 px-6 md:px-0 oferta relative">
        <div className="container">
          <div className="grid md:grid-cols-5">
            <div className="col-span-2 relative">
              <div className="absolute -top-52 h-12">
                <Image
                  src={Oferta}
                  className="w-full"
                  alt="Oferta Turística | QRDgo"
                />
              </div>
            </div>
            <div className="col-span-3">
              <div className="md:w-7/12">
                <h3 className="text-white text-5xl mb-4">
                  Vive una <b>Oferta Turística</b> excepcional
                </h3>
                <p className="text-white">
                  Encuentra una gran cantidad de información turística en el{" "}
                  <b>Estado de Durango</b>, lugares increibles por visitar y
                  emocionantes momentos que siempre recordarás.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="md:pt-28 pt-12 pb-12">
        <div className="container">
          <div className="flex px-6 md:px-0 gap-y-6 flex-col md:flex-row items-center justify-center">
            <div className="w-full md:w-2/6 flex flex-col gap-y-6">
              <h3 className=" text-5xl text-[#446C93] font-bold">
                Información Turística Digital
              </h3>
              <p className="text-[#4A4A4A] text-md">
                Aquí encontrarás toda la oferta turística de Durango disponible
                en categorias, podrás acceder a cada una de sus tarjetas
                digitales y obtener una infinidad de informacion de cada una de
                estas empresas.
              </p>
              <div className="w-2/3 md:w-full">
                <Image src={Cerca} alt="Oferta Turística | QRDgo" />
              </div>
            </div>
            <div>
              <Image
                src={Totem}
                width={379}
                height={524}
                alt="Oferta Turística | QRDgo"
              />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
