import * as React from "react";
import { SVGProps } from "react";
const QRHome = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={53}
    height={52}
    fill="none"
    {...props}
  >
    <path
      fill="#fff"
      d="M40.433 0a2.187 2.187 0 1 0 0 4.374h7.344v7.344a2.187 2.187 0 1 0 4.374 0V3a3 3 0 0 0-3-3h-8.718ZM3.84 0a3 3 0 0 0-3 3v8.718a2.187 2.187 0 1 0 4.374 0V4.374h7.344a2.187 2.187 0 0 0 0-4.374H3.84ZM5.214 46.932V39.59a2.187 2.187 0 1 0-4.374 0v8.718a3 3 0 0 0 3 3h8.718a2.187 2.187 0 0 0 0-4.375H5.214ZM49.964 37.402c-1.208 0-2.187.98-2.187 2.187v7.343h-7.344a2.187 2.187 0 1 0 0 4.375h8.718a3 3 0 0 0 3-3v-8.718c0-1.208-.98-2.187-2.187-2.187ZM19.41 14.607h-4.076v4.076h4.076v-4.076Z"
    />
    <path
      fill="#fff"
      d="M25.314 24.578H9.441V8.705h15.873v15.873Zm-12.49-3.382h9.108v-9.1h-9.109v9.1ZM37.646 14.607H33.57v4.076h4.076v-4.076Z"
    />
    <path
      fill="#fff"
      d="M43.538 24.578H27.674V8.705h15.864v15.873Zm-12.482-3.382h9.1v-9.1h-9.1v9.1ZM19.41 32.623h-4.076V36.7h4.076v-4.075Z"
    />
    <path
      fill="#fff"
      d="M25.314 42.602H9.441V26.729h15.873v15.873Zm-12.49-3.391h9.108v-9.118h-9.109v9.118ZM37.646 32.623H33.57V36.7h4.076v-4.075Z"
    />
    <path
      fill="#fff"
      d="M43.538 42.602H27.674V26.729h15.864v15.873ZM31.056 39.21h9.1v-9.118h-9.1v9.118Z"
    />
  </svg>
);
export default QRHome;
