import Image from "next/image";
import LogoQR from "../../public/QRDGO_icon_blue_pink.svg";
import { Related } from "./Related";
import Link from "next/link";
import Logo from "../../icons/Logo";
import BgFooterImage from "../../public/bg-footer.jpg";

export const Footer = () => {
  return (
    <>
      <footer>
        <div className="pt-12 relative">
          <div className="absolute inset-0 -z-10">
            <Image
              src={BgFooterImage}
              alt="QRDGO"
              layout="fill"
              objectFit="cover"
              quality={100}
            />
          </div>
          <div className="container">
            <div className="py-6 border-b mb-6 border-b-white border-opacity-25">
              <h3 className="text-2xl text-white text-center px-4">
                Mejoramos <b>la experiencia de los viajeros</b> que visitan
                nuestro estado
              </h3>
            </div>
            <div className="flex flex-col md:flex-row text-center md:text-left gap-y-6 md:gap-y-0 items-center md:justify-between pb-12 ">
              <Logo />
              <div className="w-full md:w-1/2 pt-2 mx-auto md:px-0">
                <p className="text-white text-sm text-center px-4">
                  Ofrecemos contenidos digitales accesibles en sus dispositivos
                  móviles. Con exposición en la mayor parte de los centros
                  turísticos y hoteles en donde encontrarás a través de el
                  escaneo de códigos QR contenido descabale e información
                  valiosa en tus recorridos turísticos.
                </p>
              </div>
              <div>
                <nav className=" list-none text-white uppercase text-xs">
                  <ul className="flex flex-col gap-y-4">
                    <li>Inicio</li>
                    <li>Folletería</li>
                    <li>Targetas Digitales</li>
                    <li>Micrositios</li>
                  </ul>
                </nav>
              </div>
            </div>
          </div>
          <div className="subfooter bg-[#000] bg-opacity-30 text-center py-2 px-4 md:px-0">
            <p className="m-0 text-white text-xs md:text-md font-light">
              <b className="font-bold">QRDGO</b> | Plataforma Turística Digital
              | Durango, Dgo. México | Todos los Derechos Reservados Ⓒ 2022
            </p>
          </div>
        </div>
      </footer>
    </>
  );
};
