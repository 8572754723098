import Image from "next/image";
import Link from "next/link";

import Folleteria from "../../public/related/folleteria.jpg";
import Micrositios from "../../public/related/micrositios.jpg";
import Tarjetas from "../../public/related/tarjetas-digitales.jpg";

export const SeeMore = () => {
  return (
    <section className=" pt-12 pb-48 px-12 md:px-0">
      <h3 className="text-center text-2xl block w-full mb-12 text-[#446C93]">
        <span className=" font-bold">Información turística</span>{" "}
        <span className=" font-light">unificada</span>
      </h3>
      <div className="container grid grid-cols-1 md:grid-cols-3 gap-12">
        <div className="hover:scale-105 hover:shadow-2xl  transition-all duration-500 ">
          <Link href={"/folleteria"}>
            <a>
              <div>
                <Image
                  src={Tarjetas}
                  layout="responsive"
                  className=" rounded-t-xl shw"
                  alt="Tarjetas Digitales | QRDgo"
                />
              </div>
              <div className="deg-yellow rounded-b-xl py-4 px-6 h-32 shw">
                <h3 className="mb text-xl font-bold text-white">FOLLETERÍA</h3>
                <p className="text-white text-sm m-0">
                  Diseños atractivos,Información concisa y accesible en formato
                  digital.
                </p>
              </div>
            </a>
          </Link>
        </div>
        <div className="hover:scale-105 hover:shadow-2xl  transition-all duration-500 ">
          <Link href={"/tarjetas"}>
            <a>
              <div>
                <Image
                  src={Folleteria}
                  layout="responsive"
                  className=" rounded-t-xl shw"
                  alt="Folletería Digital | QRDgo"
                />
              </div>
              <div className="deg-green rounded-b-xl py-4 px-6 h-32 shw">
                <h3 className="mb text-xl font-bold text-white">
                  TARJETAS DIGITALES
                </h3>
                <p className="text-white text-sm m-0">
                  Directorio en formato digital para promocionar tu lugares
                  turísticos, marcas o eventos de manera efectiva.
                </p>
              </div>
            </a>
          </Link>
        </div>
        <div className="hover:scale-105 hover:shadow-2xl  transition-all duration-500 ">
          <a
            href="https://micrositios.qrdgo.mx"
            target="_blank"
            rel="noreferrer noopener"
          >
            <div>
              <Image
                src={Micrositios}
                layout="responsive"
                className=" rounded-t-xl shw"
                alt="Micrositios Turísticos | QRDgo"
              />
            </div>
            <div className="deg-red rounded-b-xl py-4 px-6 h-32 shw">
              <h3 className="mb text-xl font-bold text-white">MICROSITIOS</h3>
              <p className="text-white text-sm m-0">
                {" "}
                Páginas web compactas y enfocadas, maximizando impacto con
                contenido relevante y diseño atractivo.
              </p>
            </div>
          </a>
        </div>
      </div>
    </section>
  );
};
