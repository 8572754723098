import Link from "next/link";

export const Menu = () => {
  return (
    <div className="hidden md:block">
      <ul className="flex uppercase">
        <li className="mr-3 md:mr-6">
          <Link href={"/"}>
            <a className="text-white hover:text-blue-800 text-sm md:text-md">
              Inicio
              <span className="w-1/2 md:mt-1 block m-auto"></span>
            </a>
          </Link>
        </li>
        <li className="md:mr-6 ">
          <Link href={"/folleteria"}>
            <a className="text-white hover:text-blue-800 text-sm md:text-md">
              Folletería
            </a>
          </Link>
        </li>
        <li className="md:mr-6 ">
          <Link href={"/tarjetas"}>
            <a className="text-white hover:text-blue-800 text-sm md:text-md">
              TARJETAS DIGITALES
            </a>
          </Link>
        </li>
        <li className="md:mr-6 ">
          <a
            className="text-white hover:text-blue-800 text-sm md:text-md"
            href="https://micrositios.qrdgo.mx"
            target="_blank"
            rel="noreferrer noopener"
          >
            MICROSITIOS
          </a>
        </li>
      </ul>
    </div>
  );
};
