import { Gradient } from "../Helpers/Gradient";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import { motion, useAnimation } from "framer-motion";

import Image from "next/image";

import slide1 from "../../public/slider/item-1.png";
import item2 from "../../public/slider/item-2.png";
import item4 from "../../public/slider/item-4.png";
import item5 from "../../public/slider/item-5.png";
import item6 from "../../public/slider/item-6.png";
import slide2 from "../../public/slider/bg2xsld.jpg";
import slide3 from "../../public/slider/bg3xsld.jpg";
import slide1bg from "../../public/slider/bg-slide_001.jpg";
import slide2bg from "../../public/slider/bg-slide_002.jpg";
import slide3bg from "../../public/slider/bg-slide_003.jpg";
import { useEffect } from "react";
import Link from "next/link";
import QRHome from "../../icons/QRHome";

export const Slider = () => {
  const slideVariants = {
    hidden: { opacity: 0, y: 50 },
    visible: { opacity: 1, y: 0, transition: { staggerChildren: 0.2 } },
  };

  const elementVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: { opacity: 1, y: 0 },
  };

  const controls = useAnimation();

  useEffect(() => {
    controls.start("visible");
  }, [controls]);

  return (
    <section className="relative sld">
      <Carousel
        showThumbs={false}
        showStatus={false}
        autoPlay={true}
        interval={5000}
        emulateTouch={true}
        showIndicators={false}
      >
        <motion.div
          className="slides relative flex"
          initial="hidden"
          animate={controls}
          variants={{
            hidden: { opacity: 0 },
            visible: { opacity: 1 },
          }}
        >
          <div className="absolute inset-0 -z-10">
            <Image
              src={slide1bg}
              alt="QRDGO"
              layout="fill"
              objectFit="cover"
              quality={100}
            />
          </div>
          <motion.div className="container flex justify-center items-end relative z-50 px-12 md:px-0">
            <motion.div className="flex items-end justify-center">
              <motion.div
                variants={elementVariants}
                className="hidden md:block"
              >
                <Image
                  src={item2}
                  width={297}
                  height={410}
                  alt="QRDgo | Turismo Digital"
                />
              </motion.div>
              <motion.div className="flex gap-3 flex-col md:w-1/2 text-center md:text-left pb-12">
                <motion.div
                  variants={elementVariants}
                  className="flex justify-center md:justify-start"
                >
                  <QRHome />
                </motion.div>
                <motion.div>
                  <Image
                    src={slide1}
                    width={488}
                    height={76}
                    alt="QRDgo | Turismo Digital"
                  />
                </motion.div>

                <motion.h3
                  variants={elementVariants}
                  className="text-white text-5xl m-0"
                >
                  <b>Mucho</b>{" "}
                  <span className=" font-light">por disfrutar</span>
                </motion.h3>
                <motion.p
                  variants={elementVariants}
                  className="text-white text-md font-light"
                >
                  Conoce los servicios turísticos, historia, cultura,
                  naturaleza, cine, pueblos mágicos, aventura y mucho más...
                </motion.p>
                <motion.p variants={elementVariants}>
                  <Link href="/tarjeta">
                    <a className=" bg-[#000] bg-opacity-70 py-2 px-6 pointer text-xs rounded-lg text-white">
                      Tarjetas Digitales de Durango
                    </a>
                  </Link>
                </motion.p>
              </motion.div>
            </motion.div>
          </motion.div>
        </motion.div>
        <motion.div
          className="slides relative flex"
          initial="hidden"
          animate={controls}
          variants={{
            hidden: { opacity: 0 },
            visible: { opacity: 1 },
          }}
        >
          <div className="absolute inset-0 -z-10">
            <Image
              src={slide2bg}
              alt="QRDGO"
              layout="fill"
              objectFit="cover"
              quality={100}
            />
          </div>
          <motion.div className="container flex justify-center items-end relative z-50 px-12 md:px-0">
            <motion.div className="flex items-end justify-center">
              <motion.div
                variants={elementVariants}
                className="hidden md:block"
              >
                <Image
                  src={item4}
                  width={297}
                  height={410}
                  alt="QRDgo | Turismo Digital"
                />
              </motion.div>
              <motion.div className="flex gap-3 flex-col md:w-1/2 text-center md:text-left pb-12">
                <motion.div
                  variants={elementVariants}
                  className="flex justify-center md:justify-start"
                >
                  <QRHome />
                </motion.div>
                <motion.div>
                  <Image
                    src={item5}
                    width={488}
                    height={76}
                    alt="QRDgo | Turismo Digital"
                  />
                </motion.div>

                <motion.h3
                  variants={elementVariants}
                  className="text-white text-5xl m-0"
                >
                  <b>Increíbles </b>{" "}
                  <span className=" font-light">lugares</span>
                </motion.h3>
                <motion.p
                  variants={elementVariants}
                  className="text-white text-md font-light"
                >
                  Conoce lugares increíbles por visitar y vive momentos que
                  siempre recordarás.
                </motion.p>
                <motion.p variants={elementVariants}>
                  <Link href="/folleteria">
                    <a className=" bg-[#000] bg-opacity-70 py-2 px-6 pointer text-xs rounded-lg text-white">
                      Folletería Digital
                    </a>
                  </Link>
                </motion.p>
              </motion.div>
            </motion.div>
          </motion.div>
        </motion.div>
        <motion.div
          className="slides relative flex"
          initial="hidden"
          animate={controls}
          variants={{
            hidden: { opacity: 0 },
            visible: { opacity: 1 },
          }}
        >
          <div className="absolute inset-0 -z-10">
            <Image
              src={slide3bg}
              alt="QRDGO"
              layout="fill"
              objectFit="cover"
              quality={100}
            />
          </div>
          <motion.div className="container flex justify-center items-end relative z-50 px-12 md:px-0">
            <motion.div className="flex items-end justify-center">
              <motion.div
                variants={elementVariants}
                className="hidden md:block"
              >
                <Image
                  src={item6}
                  width={335}
                  height={328}
                  alt="QRDgo | Turismo Digital"
                />
              </motion.div>
              <motion.div className="flex gap-3 flex-col md:w-1/2 text-center md:text-left pb-12">
                <motion.div
                  variants={elementVariants}
                  className="flex justify-center md:justify-start"
                >
                  <QRHome />
                </motion.div>
                <motion.h3
                  variants={elementVariants}
                  className="text-white text-5xl m-0"
                >
                  <b>Información </b>{" "}
                  <span className=" font-light">al instante</span>
                </motion.h3>
                <motion.p
                  variants={elementVariants}
                  className="text-white text-md font-light"
                >
                  Escanea los códigos QR que se encuentran en la mayoría de
                  centros turísticos y hoteles, los cuales te darán información
                  valiosa en tus paseos.
                </motion.p>
                <motion.p variants={elementVariants}>
                  <Link href="/folleteria">
                    <a className="  bg-purple-400 opacity-70 py-2 px-6 pointer text-xs rounded-lg text-white">
                      Folletería Digital
                    </a>
                  </Link>
                </motion.p>
              </motion.div>
            </motion.div>
          </motion.div>
        </motion.div>
      </Carousel>
    </section>
  );
};
