import type { NextPage } from "next";
import { Directory } from "../components/Home/Directory";
import { Microsites } from "../components/Home/Microsites";
import { SeeMore } from "../components/Home/SeeMore";
import { Slider } from "../components/Home/Slider";
import { Layout } from "../layouts/MainLayout";
import { Experience } from "../components/Home/Experience";

const Home: NextPage = () => {
  return (
    <Layout inhome={true}>
      <Slider />

      {/* <Microsites /> */}
      <SeeMore />
      <Experience />
    </Layout>
  );
};

export default Home;
