
import Head from 'next/head';
import React from 'react'
import { Footer } from '../components/Home/Footer';
import { Navbar } from '../components/Home/Navbar';
import { motion } from 'framer-motion'

type Props = {
  children?: React.ReactNode,
  inhome:Boolean
};

const variants = {
  hidden: { opacity: 0, x: -200, y: 0 },
  enter: { opacity: 1, x: 0, y: 0 },
  exit: { opacity: 0, x: 0, y: -100 },
};

export const Layout:React.FC<Props> = ( { children, inhome } ) => {
  return (
    <>
      <Head>
        <title>QRDGO</title>
        <meta name="description" content='Plataforma Digital Durango' />
        <meta property="og:title" content="QRDGO" key="og-title"/>
        <meta property="og:description" content="Plataforma Digital Durango" key="og-desc"/>
        <meta property="og:url" content="https://qrdgo.mx" key="og-url"/>
        <meta property="og:image" content="/thunmbshare.jpg" key="og-image" />
        <meta property="og:site_name" content="mysitename" key="og-site" />
        <meta name="twitter:title" content="QRDGO" key="tw-title"/>
        <meta name="twitter:description" content="Plataforma Digital Durango" key="tw-desc"/>
        <meta name="twitter:image" content="/thunmbshare.jpg" key="tw-image"/>
        <meta name="twitter:card" content="summary_large_image" key="tw-card"/>
      </Head>
      <Navbar inhome={inhome}/>
      <motion.main
          initial="hidden"
          animate="enter"
          exit="exit"
          variants={variants}
          transition={{ type: 'linear' }}          
      >
        <main>
          {children}
        </main>
      </motion.main>
    
      <Footer />
    </>
  )
}
